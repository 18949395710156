import React from "react";
import "./Description.css";

const Description = () => {
  return (
    <div id="description">
      <h1>Loop Tube: The Ultimate YouTube Video Looping Tool</h1>
      <div id="content">
        <section id="what-is-loop-tube">
          <h2>What is Loop Tube?</h2>
          <p>
            Loop Tube is a cutting-edge YouTube video player that allows you to
            seamlessly loop your favorite videos. Whether you're practicing a
            song, enjoying relaxing background music, or revisiting memorable
            moments, Loop Tube provides a unique way to customize your video
            playback experience with ease.
          </p>
        </section>
        <section id="how-to-use">
          <h2>How to Use Loop Tube</h2>
          <p>
            Getting started with Loop Tube is simple. Paste any YouTube video
            link into the input field and click "Load Video" to start watching.
            Use the interactive controls to rewind, pause, or set looping start
            and end points for precise playback. Perfect for tutorials, music
            videos, and study sessions!
          </p>
        </section>
        <section id="button-guide">
          <h2>Button Guide: Mastering Loop Tube's Features</h2>
          <ul>
            <li>
              <strong>⏪ Rewind:</strong> Instantly jump back 5 seconds to
              replay key parts of the video.
            </li>
            <li>
              <strong>▶️ Play / ⏸ Pause:</strong> Easily toggle between
              playing and pausing your video.
            </li>
            <li>
              <strong>🔄 Loop Start/End:</strong> Set custom start and end
              times to loop specific sections of the video.
            </li>
            <li>
              <strong>🔁 Reset:</strong> Clear your loop settings and play the
              video from the beginning.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Description;
