import React, { useState,Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Description from "./components/Description";
import "./App.css";
const Terms = React.lazy(() => import("./components/Terms"));
const Privacy = React.lazy(() => import("./components/Privacy"));

const VinylPlayer = React.lazy(() => import("./components/VinylPlayer")); 

const App = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [videoId, setVideoId] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoUrlChange = (e) => {
    setVideoUrl(e.target.value);
  };

  const handleLoadVideo = () => {
    const id = extractVideoId(videoUrl);
    if (id) {
      setVideoId(id);
    } else {
      alert("Invalid YouTube URL. Please try again.");
    }
  };

  const extractVideoId = (url) => {
    const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const handlePlayerStateChange = (event) => {
    setIsPlaying(event.data === 1); 
  };

  return (
    <Router>
      <div id="app-container">
        <div id="main-content">
        <Routes>
          <Route
            path="/"
            element={
              <>
                
                <div className="mt-6 flex justify-center px-4 sm:px-6 lg:px-10">
                  <input
                    type="text"
                    placeholder="Paste YouTube URL here"
                    className="w-full max-w-md p-2 border rounded-l shadow-sm text-sm sm:text-base"
                    value={videoUrl}
                    onChange={handleVideoUrlChange}
                  />
                  <button
                    onClick={handleLoadVideo}
                    className="bg-customGreen text-white px-4 rounded-r text-sm sm:text-base"
                  >
                    Load
                  </button>
                </div>
                <Suspense fallback={<div>Loading Player...</div>}>
                  <VinylPlayer
                    videoId={videoId || "dQw4w9WgXcQ"}
                    isPlaying={isPlaying}
                    onStateChange={handlePlayerStateChange}
                  />
                </Suspense>
                <div className="w-full px-4 sm:px-6 lg:px-10">
                <Description id="description"/>
                </div>
              </>
            }
          />
          <Route path="/terms" element={
            <Suspense fallback={<div>Loading Terms...</div>}><Terms /></Suspense>
          } />
          <Route path="/privacy" element={
            <Suspense fallback={<div>Loading Privacy Policy...</div>}><Privacy /></Suspense>
          } />
        </Routes>
        </div>
        <footer id="footer" className="bg-neutral-800 text-neutral-100 text-center py-4">
          <nav id="footer-nav" className="flex flex-row justify-center items-center">
            <Link to="/" className="px-4 hover:underline">
              Home
            </Link>
            <Link to="/terms" className="px-4 hover:underline">
              Terms
            </Link>
            <Link to="/privacy" className="px-4 hover:underline">
              Privacy
            </Link>
            <a
              href="mailto:support@looptube.org"
              className="px-4 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact
            </a>
            <a
              href="https://www.facebook.com/LoopTube"
              className="px-4 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </nav>
          <p className="footer-text text-sm">© {new Date().getFullYear()} LoopTube.org. All Rights Reserved.</p>
        </footer>
      </div>
    </Router>
  );
};

export default App;
